
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
//import PropTypes from 'prop'
import Head from 'next/head'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import HomeTab from '../components/home/HomeTab'
import { PRODUCTS_PER_PAGE, PAGINATION_RANGE, TITLE_SEPARATOR, ADSENSE_PUB_ID, ADSENSE_BANNER_TOP, ADSENSE_BANNER_BOTTOM, ADSENSE_ENABLE_TEST_ADS } from '../utils/constants'
import PageBody from '../components/PageBody'
import LoadingView from '../components/LoadingView'
import { useEffect, useState } from 'react'
import { API_OPTIONS } from './api/[...v1]'
import { STATUS_CODES } from '../api'
import { getSession } from 'next-auth/client'
import { Box, Button, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import Products from '../components/home/Products'
import CosmoboxCats from '../components/CosmoboxCats'
import ImageView from '../views/ImageView'
import Link from '../views/Link'
import SiteAd from '../components/home/SiteAd'
import BodySvg from '../components/BodySvg'
import Pagination from "../views/Pagination";
import GoogleAdsense from '../components/GoogleAdsense'
import { textEmpty } from '../utils/functions'

/*
select cats.*, subcats.id as subcat_id, subcats.name as subcat_name
from `cats`, subcats
where exists (
    select * from `products` where `cats`.`id` = `products`.`cat` and `subcats`.`id` = `products`.`subcat`
)*/
const Home = ({cats, posts, cursor, viewer}) => {
  const { t, lang } = useTranslation('home')
  const router = useRouter()
  console.log("Page:A", router?.query?.page)
  const page = router?.query?.page || 1
  console.log("Page:B", page)

  //const [catsState, setCatsState] = useState([])
  //const [postsState, setPostsState] = useState([]) 
  const [catsState, setCatsState] = useState(cats || [])
  const [postsState, setPostsState] = useState(posts) 

  const [viewerState, setViewerState] = useState(viewer)

  //update cats
  useEffect(() => {
    setCatsState(cats)
    console.log(`Catz:`, cats)
  }, [cats])
  //update posts
  useEffect(() => {
    setPostsState(posts)
  }, [posts])
  //update viewer
  useEffect(() => {
    setViewerState(viewer)
  }, [viewer])

/*
  if (!catsState || !postsState) {
    return(
        <div>
            <Head>
                <title>{t('common:site-desc')} {TITLE_SEPARATOR} {t('header:site-name')}</title>
            </Head>
            <PageBody navType={PageBody.vars.NAV_TYPES.home}>
                <LoadingView />
            </PageBody>
        </div>
    )
  }
*/

  return (
    <div>
      <Head>
        <title>{t('common:site-desc')} {TITLE_SEPARATOR} {t('header:site-name')}</title>
        <meta name="description" content={t('common:site-desc')} />
      </Head>
      <PageBody navType={PageBody.vars.NAV_TYPES.home} className="m-0 p-0 w-100 mw-100 h-100">
        <BodySvg />
        <Flex position="relative" direction={{base: "column", md: "row"}} w="100%" h="100%" 
        pt={{base: "0px", md: "0px"}}>
          <Box pt={{base: "0px", md: "16px"}} position="relative" w={{md: "305px"}} maxW={{md: "25%"}} h="100%">
            <CosmoboxCats cats={catsState} />
          </Box>
          <Box p="5" pt="16px" w={{base: "100%", md: "75%"}} ml={{base: 0, md: "2rem"}} h="100%">
            <SiteAd />
            {
                postsState && postsState.length > 0?
                <GoogleAdsense
                  client={ADSENSE_PUB_ID}
                  slot={ADSENSE_BANNER_TOP}
                  style={{ display: 'block' }}
                  format='horizontal'
                  responsive='false'
                  enableTest={ADSENSE_ENABLE_TEST_ADS}
                />
                : null
            }
            
            <Box mt="30px" mb="12px" fontSize="20px" fontWeight="bold" lineHeight="1.4em" color="#4f4f4f">
              {t('trending-listings')}
            </Box>
            <Products products={postsState} viewer={viewerState} /> 
            {
                postsState && postsState.length > 0?
                <GoogleAdsense
                  client={ADSENSE_PUB_ID}
                  slot={ADSENSE_BANNER_BOTTOM}
                  style={{ display: 'block' }}
                  format='horizontal'
                  responsive='false'
                  enableTest={ADSENSE_ENABLE_TEST_ADS}
                />
                : null
            }
            
            <Pagination mt="1rem"
              placement="center" page={page} range={PAGINATION_RANGE} 
              totalItems={cursor.totalItems} 
              rowsPerPage={cursor.rowsPerPage} 
            />
          </Box>
        </Flex>
      </PageBody>
    </div>
  )
}

export default Home

 const _getServerSideProps = async ({ params, req, locale, query }) => {
  const Sequelize = require("sequelize")
  const getDb = require('../database/get-db')
  const DB = getDb(API_OPTIONS.database)
  
  var props = {}
  var redirect = null

  const { Cat, SubCat, Product, User, Review, UserList } = getTables(API_OPTIONS.database, ["Cat", "SubCat", "Product", "User", "Review", "UserList"], locale, API_OPTIONS.defaultLocale)
  console.log("D_L:33", locale, API_OPTIONS.defaultLocale)
  try {
    //select the current cat is the current category the user want to see its products or more 
    //of its products
    var currentCat = params?.cat
    var currentCatId
    var noCats = query.no_cats
    console.log("CH_:", currentCat, params)
    //get cats along with subcats
    var cats = []
    if(!noCats) {/*
      Cat.hasMany(SubCat, {foreignKey: 'cat_id'})
      SubCat.belongsTo(Cat, {foreignKey: 'cat_id'})
      cats = await Cat.findAll({
        //where: Sequelize.ex
        include: [{
            model: SubCat
        }],
        order: [
          ['weight', 'DESC'],
          ['name', 'ASC']
        ]
      }) || []*/
      
      const re = new RegExp("^([a-zA-Z]{2})$")
      const l = textEmpty(locale) || !re.test(locale) || locale.toLowerCase() == 'en'? '' : `_${locale}`
      console.log('LOCALE_SUFFIX:', l)
      
      const query = `
      select cats.id, cats.text_id, cats.name${l} as name, cats.description${l} as description, cats.image, 
      cats.weight, cats.is_custom, cats.no_subcat_placeholder, subcats.id as subcat_id, 
      subcats.text_id as subcat_text_id, subcats.name${l} as subcat_name, 
      subcats.weight as subcat_weight
      from cats, subcats
      where exists (
          select * from products where cats.id = products.cat and subcats.id = products.subcat
      )
      ORDER BY cats.weight DESC, cats.name ASC, subcats.weight DESC, subcats.name ASC`

      var cats = JSON.parse(JSON.stringify(await DB.query(query)))[0]
      //console.log('LOCALE_SUFFIX_B:', cats)
      const catsTemp = []
      const catsMap = {}
      var totalAdded = 0
      for(var i = 0; i < cats.length; i++) {
        var subcat = {
            "id": cats[i].subcat_id,
            "text_id": cats[i].subcat_text_id,
            "cat_id": cats[i].id,
            "name": cats[i].subcat_name,
            "weight": cats[i].subcat_weight
        }
        if (!catsMap.hasOwnProperty(cats[i].id)) {
          catsMap[cats[i].id] = totalAdded
          totalAdded++
          catsTemp.push({
            id: cats[i].id,
            text_id: cats[i].text_id,
            name: cats[i].name,
            description: cats[i].description,
            image: cats[i].image,
            weight: cats[i].weight,
            is_custom: cats[i].is_custom,
            no_subcat_placeholder: cats[i].no_subcat_placeholder,
            subcats: [subcat]
          })
        } else {
          catsTemp[catsMap[cats[i].id]].subcats.push(subcat)
        }
      }
      cats = catsTemp
      console.log('LOCALE_SUFFIX_C:', cats)

      if(!currentCat) {
        currentCat = cats[0].text_id
        currentCatId = cats[0].id
      }
      /*
      for(var i = 0; i < cats.length; i++) {

      }

      console.log("Caty:", cats.length, cats)*/
     
    }
    
    
    var viewer = null
    //get the viewer id
    var userId
    const session = await getSession({req})
    var user = session?.user || {}
    userId = user?.id
    viewer = {id: user.id, image: user.image}

    //get the products of the current cat
    var posts = []
    var page = query?.page || 1
    if(page < 1) page = 1
    var offset = (page - 1) * PRODUCTS_PER_PAGE

    //count the total products
    var totalProducts = await Product.count() || 0
    var cursor = {totalItems: totalProducts, rowsPerPage: PRODUCTS_PER_PAGE}

    var query = `
    SELECT 

    \`products\`.*, 

    \`cats\`.\`text_id\` AS \`catTextId\`,
    \`subcats\`.\`text_id\` AS \`subcatTextId\`,

    \`seller\`.\`name\` AS \`sellerName\`, \`seller\`.\`username\` AS \`sellerUsername\`, 
    \`seller\`.\`image\` AS \`sellerImage\`, 

    IF(\`user_lists\`.\`product_id\` > 0, true, false) AS \`liked\`,

    COALESCE(AVG(\`reviews\`.\`rating\`), 0) AS \`avgRating\`, 
    COALESCE(COUNT(\`reviews\`.\`rating\`), 0) AS \`totalRatings\` 

    FROM (
        SELECT \`products\`.\`id\`, \`products\`.\`seller_id\` AS \`sellerId\`, \`products\`.\`cat\`, \`products\`.\`subcat\`, 
        \`products\`.\`country\`, \`products\`.\`title\`, \`products\`.\`description\`, \`products\`.\`currency_code\`, 
        \`products\`.\`price\`, \`products\`.\`photos\`, \`products\`.\`reviewed\`, \`products\`.\`sold_out\`, 
        \`products\`.\`is_flash\`, \`products\`.\`flash_last_update\`, \`products\`.\`created_at\` AS \`createdAt\`, 
        \`products\`.\`updated_at\` AS \`updatedAt\`, 
        \`products\`.\`booster_expiry\` AS \`boosterExpiry\` FROM \`products\` AS \`products\`
        WHERE reviewed>-1
        ORDER BY \`products\`.\`flash_last_update\` DESC, boosterExpiry DESC, updatedAt DESC LIMIT ?, ?
    ) AS \`products\` 
    LEFT OUTER JOIN \`cats\` ON \`products\`.\`cat\` = \`cats\`.\`id\`
    LEFT OUTER JOIN \`subcats\` ON \`products\`.\`subcat\` = \`subcats\`.\`id\`
    LEFT OUTER JOIN \`nextauth_users\` AS \`seller\` ON \`products\`.\`sellerId\` = \`seller\`.\`id\` 
    LEFT OUTER JOIN \`reviews\` AS \`reviews\` ON \`products\`.\`id\` = \`reviews\`.\`product_id\` 
    LEFT OUTER JOIN (SELECT \`product_id\` FROM  \`user_lists\` WHERE \`removed\` = 0 AND \`user_id\` = ?) AS \`user_lists\` ON \`user_lists\`.\`product_id\` = \`products\`.\`id\`
    GROUP BY id ORDER BY \`reviewed\` DESC, \`flash_last_update\` DESC, boosterExpiry DESC, updatedAt DESC
    `
    var products = await DB.query(query, {
      replacements: [offset, PRODUCTS_PER_PAGE, viewer?.id || -1],
      raw: true, 
      type: Sequelize.QueryTypes.SELECT,
      model: Product,
      mapToModel: true
    })

    if(props.errorCode) {
        if(props.errorCode == STATUS_CODES.NOT_FOUND) return {notFound: true}
        return {
            redirect: {
                destination: `/errors/${props.errorCode}`,
                permanent: false,
            }
        }

    } else if(redirect) {
        return {
            redirect: {
              destination: redirect,
              permanent: true,
            }
        }

    } else {
        props = JSON.parse(JSON.stringify(
          {
            cats: cats,
            posts: products,
            cursor: cursor,
            viewer: viewer
          }
        ))
        console.log("PROPSZ:", props)
        return {
            props: props
        }
    }
      
  } catch(e) {
      console.log("CATZ::E", "SERVER_ERROR", e)
      return {
          redirect: {
              destination: `/errors/${STATUS_CODES.INTERNAL_SERVER_ERROR}`,
              permanent: false,
          }
      }
  }
}


    export async function getServerSideProps(ctx) {
        let res = _getServerSideProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getServerSideProps',
              ...__i18nConfig,
              
            }))
          }
        }
    }
  